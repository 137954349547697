<template>
<div class="content-wrapper">
  <bo-page-header></bo-page-header>
  <div class="content pt-0">
    <b-card no-body>
      <div class="header_card mb-0">
        <h4>Judul Topik: <span class="text-primary font-weight-bold">Alutista</span></h4>
      </div>
      <b-card-body>
        <b-row cols="1" cols-md="2"> 
          <b-col>
            <b-form-group
              label-cols-md="4"
              content-cols-md=""
              label="Nama Topik"
              label-for="projectName"
            >
              <b-form-input id="projectName" value="Alutista" />
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              content-cols-md=""
              label="Tanggal Awal Pengambilan Data"
              label-for="projectDuration"
            >
              <b-form-datepicker />
            </b-form-group>
          </b-col>
        </b-row>
        <b-tabs nav-class="nav-tabs-top bg_tab">
          <b-tab title="Kata Kunci">
            <b-row>
              <b-col md="11">
                <b-row cols="1" cols-md="3">
                  <b-col>
                    <div class="keyword-info">
                      <h5 class="keyword-info__title">Prioritas Utama</h5>
                      <span class="keyword-info__exp">Kata kunci atau frasa utama yang datanya akan dikumpulkan oleh proyek Anda</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="keyword-info">
                      <h5 class="keyword-info__title">Tambahan</h5>
                      <span class="keyword-info__exp">Kata kunci tambahan - setiap kata kunci harus muncul agar penyebutan dapat dikumpulkan</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="keyword-info">
                      <h5 class="keyword-info__title">Pengecualian</h5>
                      <span class="keyword-info__exp">Kata kunci pengecualian - kata kunci yang tidak akan muncul jika penyebutanya dikumpulkan</span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="keywords">
              <b-row>
                <b-col md="11">
                  <b-row cols="1" cols-md="3">
                    <b-col>
                      <b-form-input class="border_inp" value="Draft Dokumen Alutista" />
                    </b-col>
                    <b-col>
                      <b-form-input/>
                      <b-form-text >Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                    <b-col>
                       <b-form-input/>
                      <b-form-text>Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="1">
                  <b-button class="btn-rounded btn-icon" variant="outline-danger" size="sm">
                    <i class="icon-cross"></i>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="keywords">
              <b-row>
                <b-col md="11">
                  <b-row cols="1" cols-md="3">
                    <b-col>
                      <b-form-input  class="border_inp" value="Rencana Belanja Alutista" />
                    </b-col>
                    <b-col>
                      <b-form-input />
                      <b-form-text>Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                    <b-col>
                      <b-form-input />
                      <b-form-text>Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="1">
                  <b-button class="btn-rounded btn-icon" variant="outline-danger" size="sm">
                    <i class="icon-cross"></i>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="keywords">
              <b-row>
                <b-col md="11">
                  <b-row cols="1" cols-md="3">
                    <b-col>
                      <b-form-input  class="border_inp" value="Anggaran Pertahanan" />
                    </b-col>
                    <b-col>
                      <b-form-input />
                      <b-form-text>Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                    <b-col>
                      <b-form-input />
                      <b-form-text>Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="1">
                  <b-button class="btn-rounded btn-icon" variant="outline-danger" size="sm">
                    <i class="icon-cross"></i>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="keywords">
              <b-row>
                <b-col md="11">
                  <b-row cols="1" cols-md="3">
                    <b-col>
                      <b-form-input  class="border_inp" value="Connie Bakrie" />
                    </b-col>
                    <b-col>
                      <b-form-input />
                      <b-form-text>Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                    <b-col>
                      <b-form-input />
                      <b-form-text>Pisahkan kata kunci dengan koma (,)</b-form-text>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="1">
                  <b-button class="btn-rounded btn-icon" variant="outline-danger" size="sm">
                    <i class="icon-cross"></i>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="keywords">
              <div class="text-right">
                <b-button variant="transparent" class="bg-indigo-400">
                  <i class="icon-plus2"></i> Tambah Kata Kunci
                </b-button>
              </div>
            </div>
            <b-row>
              <b-col md="6">
                <b-form-group label="Bahasa" label-for="issueLang">
                  <v-select
                    id="issueLang"
                    :options="issueLang"
                    v-model="selIssueLang"
                    label="text"
                    placeholder="Pilih Rentang Waktu"
                  />
                </b-form-group>
                <b-form-group label="Durasi Penggalian Data" label-for="issueDuration">
                  <b-form-radio-group :options="durationOption" v-model="dataDuration" />
                </b-form-group>
                <b-form-group v-if="dataDuration=='Y'" label="Rentang Waktu" label-for="issueDuration">
                  <v-select
                    id="issueDuration"
                    :options="issueDuration"
                    v-model="selIssueDuration"
                    label="text"
                    placeholder="Pilih Rentang Waktu"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Sumber">
            <div class="media-sources" v-for="(v, k) in issueSources" :key="k">
              <b-row>
                <b-col md="4">
                  <b-form-group label="Pilih Sumber" label-for="sourceName">
                    <v-select id="sourceName" :options="sourceOptions" v-model="v.sourceName" />
                  </b-form-group>
                </b-col>
                <template v-if="v.sourceName.value == 'social'">
                  <b-col md="4">
                    <b-form-group label="Ambil Dari" label-for="projectSources">
                      <b-form-checkbox-group
                        class="source-checkbox"
                        id="projectSources"
                        :options="socmedOptions"
                        v-model="v.socmedSources"
                      />
                    </b-form-group>
                  </b-col>
                </template>
                <template v-else-if="v.sourceName.value == 'web'">
                  <b-col md="4">
                    <b-form-group label="Kategori Web" label-for="webCategory">
                      <v-select id="webCategory" :options="webCategory" v-model="v.webType" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="URL" label-for="webUrl">
                      <b-input-group class="mb-2" v-for="(url, key) in v.url" :key="key">
                        <b-form-input :value="url" />
                        <template #append>
                          <b-button variant="outline-danger" size="sm"><i class="icon-trash-alt"></i></b-button>
                        </template>
                      </b-input-group>
                    </b-form-group>
                    <div class="text-right">
                      <b-button variant="outline-success">Tambah URL</b-button>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="Manajemen Pengguna">
            <div class="text-right mb-3">
              <b-button variant="transparent" v-b-toggle.collapseUser class="bg-indigo-400">
                <i class="icon-plus2 mr-2"></i>
                Tambah Pengguna
              </b-button>
            </div>
            <b-table
              :fields="userFields"
              :items="userDatas"
              bordered
              responsive
            >
              <template #cell(num)="data">
                {{ data.index += 1 }}
              </template>
              <template #cell(aksi)>
                <b-button variant="outline-danger" pill size="sm" class="btn-icon">
                  <i class="icon-trash-alt"></i>
                </b-button>
                <b-button variant="outline-info" pill size="sm" class="btn-icon ml-1">
                  <i class="icon-pencil7"></i>
                </b-button>
              </template>
            </b-table>
            <b-collapse id="collapseUser">
              <b-card title="Tambah Pengguna" class="border mb-0 shadow-none">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Nama Lengkap" label-for="userName">
                      <b-form-input id="userName" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label-for="userEmail">
                      <template #label>Email <small>(Opsional)</small></template>
                      <b-form-input id="userEmail" />
                    </b-form-group>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="4">
                    <b-form-group label="Kata Sandi" label-for="userPassword">
                      <b-form-input id="userPassword" type="password" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Konfirmasi Kata Sandi" label-for="userPassConfirm">
                      <b-form-input id="userPassConfirm" type="password" />
                    </b-form-group>
                  </b-col>
                  <div class="w-100"></div>
                  <b-col md="4">
                    <b-form-group label="Peranan" label-for="userPassConfirm">
                      <v-select :options="roleOptions" id="userPassConfirm" placeholder="Peranan Pengguna" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="text-left">
                  <b-button variant="transparent" class="bg-indigo-400 mr-1">Simpan</b-button>
                  <b-button variant="transparent">Batal</b-button>
                </div>
              </b-card>
            </b-collapse>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
    <b-card>
      <div class="text-right">
        <b-button variant="transparent">Batal</b-button>
        <b-button variant="transparent" class="bg-indigo-400 ml-1">Simpan Perubahan</b-button>
      </div>
    </b-card>
  </div>
  <bo-footer></bo-footer>
</div>
</template>
<script>
const _ = require('lodash')
import projectsData from '@/dummies/projectsData'
import GlobalVue from '../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
      counter: 0,
      projectAdded: false,
      dataDuration: 'Y',
      durationOption: [
        { text: 'Rentang Waktu:', value: 'Y' },
        { text: 'Semua Rentang Waktu', value: 'N' },
      ],
      durationDate: {},
      issueDuration: [
        { text: '12 Bulan', value: 12 },
        { text: '24 Bulan', value: 24 },
        { text: '36 Bulan', value: 36 },
      ],
      selIssueDuration: { text: '12 Bulan', value: 12 },
      issueLang: [
        { text: 'Indonesia', value: 'ID' },
        { text: 'Inggris', value: 'EN' },
      ],
      selIssueLang: { text: 'Indonesia', value: 'ID' },
      issueSources: [
        {
          sourceName: { label: 'Media Sosial', value: 'social' },
          socmedSources: ['fb', 'ig', 'tw', 'yt'],
        },
        {
          sourceName: { label: 'Web', value: 'web' },
          webType: { label: 'Portal', value: 'portal' },
          url: [
            'https://www.sumber.com',
            'https://www.techinasia.com',
            'https://www.medium.com',
          ]
        },
      ],
      sourceOptions: [
        { label: 'Media Sosial', value: 'social' },
        { label: 'Web', value: 'web' },
      ],
      socmedOptions: [
        { text: 'Facebook', value: 'fb' },
        { text: 'Instagram', value: 'ig' },
        { text: 'Twitter', value: 'tw' },
        { text: 'YouTube', value: 'yt' },
      ],
      webCategory: [
        { label: 'Portal', value: 'portal' },
        { label: 'Personal Website', value: 'personal' },
        { label: 'Blog', value: 'blog' },
        { label: 'Forum', value: 'forum' },
      ],
      userFields: [
        { key: 'num', label: '#' },
        'nama_lengkap', 'peranan', 'aksi',
      ],
      userDatas: [
        { nama_lengkap: 'M. Nur Fauzi', peranan: 'Administrator' },
        { nama_lengkap: 'Novita Resti Utami', peranan: 'Operator' },
        { nama_lengkap: 'Iwan Kurniawan', peranan: 'Approver' },
        { nama_lengkap: 'Tita Aprilianti', peranan: 'Media Counter' },
      ],
      roleOptions: [
        'Administrator', 'Operator', 'Approver', 'Media Counter'
      ]
    }
  },
  methods: {
    addProject() {
      this.counter = 10
      this.$bvModal.show('addProjectProcess')
      projectsData.addData(
        {
          name: 'Proj1',
          keyword: 'Proj1',
          hashtag: 'Proj1',
        }
      )
    },
  },
  watch: {
    counter: {
      handler(val) {
        if (val > 0) {
          this.projectAdded = true
          setTimeout(() => {
            this.counter--
          }, 1000)
        }else if (val == 0 && this.projectAdded) {
          this.$bvModal.hide('addProjectProcess')
          this.$router.push({ name: 'ProjectDashboard', params: { project: _.kebabCase(this.projName) } })
        }
      },
      immediate: true,
    }
  }
}
</script>